import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { useLocation  } from 'react-router-dom';
import banner from '../Assets/banner_white.png';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [plus, setPlus] = useState(false)
  const dropdownRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const isHomePage = window.location.pathname === '/';
  const location = useLocation();


  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const shouldBeScrolled = isHomePage && scrollPosition > 150;

      setIsScrolled(shouldBeScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isHomePage]);

  const backgroundColor = isHomePage ? (isScrolled ? '#002147' : 'linear-gradient(to top, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.8) 100%)') : '#002147';


  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };
  const closeDropdown = () => {
    setIsOpen(false);
    setPlus(false);
  };

  return (
    <section className='navbarSection'>
      <nav className={`navbar navbar-expand-sm fixed-top w-100 ${isHomePage ? (isScrolled ? 'shadow-lg' : null) : '#002147'}`} style={{ background: backgroundColor, zIndex: '1', transition: 'ease-in 0.2s' }} >
        <div className="container-fluid d-flex flex-column">
          <div className={`d-flex w-100 align-items-center justify-content-between `} >
            <p style={{ fontSize: '14px', fontFamily:'Mulish' }} className='text-light p-0 px-2 m-0'><i className="fa-solid fa-mobile-screen-button rounded-circle bg-light p-1" style={{color:'#002147'}}></i> &nbsp;+91-9994171537</p>
            <p style={{ fontSize: '14px', fontFamily:'Mulish' }} className='text-light p-0 px-2 m-0'><i className="fa-solid fa-envelope rounded-circle bg-light p-1" style={{color:'#002147'}}></i>&nbsp; shreemaruthividyalaya@gmail.com</p>
          </div>
          <div className='d-flex align-items-center justify-content-between w-100 px-2'>
            <a className="navbar-brand  text-light" style={{ fontSize: '25px' }} href="/">
              <img src={banner}  alt='logo_image' height="120"/>
            </a>
            <button className="navbar-toggler" style={{ borderColor: 'white' }} type="button" data-bs-toggle="offcanvas" data-bs-target="#demo">
              <i className="fa-solid fa-bars" style={{ color: 'white' }}></i>
            </button>

            <div className="offcanvas offcanvas-end " id="demo" style={{ flexGrow: '0', background: '#002147' }}>

              <div className="offcanvas-header">
                <h1 className="offcanvas-title text-light">MENU</h1>
                <button type="button" data-bs-dismiss="offcanvas" style={{ background: 'transparent', border: '1px solid white' }}>
                  <i className="fa-solid fa-xmark" style={{ fontSize: 'large', color: 'white' }}></i>
                </button>
              </div>
              <div className="offcanvas-body ">
                <ul className="navbar-nav">
                  <li className={`nav-item px-2 py-2  `} type="button" data-bs-dismiss="offcanvas">
                    <Link className={`nav-link text-light ${location.pathname === '/' ? 'active' : ''}`}  to='/'> <span> HOME </span> </Link>
                  </li>

                  {/* About us for pc screen  */}
                  <div
                    className="dropdown d-none d-sm-block px-2 "
                    
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave} >
                    <li
                      type="button"
                      className="text-light nav-link  dropdown-toggle  "
                      style={{ background: 'none', border: '0px', fontSize: '14px', paddingTop:'15px' }}
                    >
                      ABOUT US
                    </li>
                    <ul
                      className={`dropdown-menu ${isOpen ? 'show' : ''}`}
                      ref={dropdownRef} style={{ marginLeft: '-10px', minWidth: 'unset', marginTop: '5px' }}
                    >
                      <li onClick={closeDropdown}><Link to='/Chairman-Message' className={`dropdown-item w-100 ${location.pathname === '/Chairman-Message' ? 'active' : ''}`} >Chairman Message</Link></li>
                      <li onClick={closeDropdown}><Link  to='/Principal-Message' className="dropdown-item w-100" >Principal Message</Link></li>
                      <li onClick={closeDropdown} ><Link   to='/Mission-and-Vission' className="dropdown-item w-100" >Mission and Vission</Link></li>
                      <li onClick={closeDropdown} className=''><Link style={{ width: '100%', paddingRight:'100px' }} to='/about-us' className="dropdown-item " >About Us</Link></li>

                    </ul>
                  </div>

                  {/* About us for mobile screen  */}
                  <div id="accordion" className="d-block d-sm-none" style={{ background: '#002147' }}>
                    <div className="card py-0  nav-item" style={{ background: '#002147', borderLeft: '0', borderRight: '0' }}>
                      <div onClick={() => setPlus(!plus)} className="card-header d-flex align-items-center justify-content-between px-0 border-0" data-bs-toggle="collapse" href="#collapseOne">
                        <a className="btn nav-link text-light px-0 "  >
                          ABOUT US
                        </a>
                        {!plus ?
                          <i className="fa-solid fa-plus" style={{ color: 'white', fontSize: 'large' }}></i> :
                          <i className="fa-solid fa-minus" style={{ color: 'white', fontSize: 'large' }}></i>
                        }
                      </div>
                      <div id="collapseOne" className="collapse hide" data-bs-parent="#accordion" >
                        <div className="card-body text-light">
                          <ul style={{ listStyle: 'none' }} className='p-0'>
                            <li type="button" data-bs-dismiss="offcanvas" className='pb-1'><Link className="dropdown-item" to='/Principal-Message'>Chairman Message</Link></li>
                            <li type="button" data-bs-dismiss="offcanvas" className='pb-1'><Link className="dropdown-item" to='/Chairman-Message'>Principal Messag</Link></li>
                            <li type="button" data-bs-dismiss="offcanvas" className='pb-1'><Link className="dropdown-item" to='/Mission-and-Vission' >Mission and Vission</Link></li>
                            <li type="button" data-bs-dismiss="offcanvas" className='pb-1'><Link className="dropdown-item" to='/about-us' >About </Link></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <li className={`nav-item px-2`} type="button" data-bs-dismiss="offcanvas">
                    <Link className={`nav-link text-light   ${location.pathname === '/gallery' ? 'active' : ''}`} to='/gallery'> GALLERY </Link>
                  </li>

                  <li className={`nav-item px-2  `} type="button" data-bs-dismiss="offcanvas">
                    <Link className={`nav-link text-light  ${location.pathname === '/contact' ? 'active' : ''}`} to='/contact'> CONTACT US </Link>
                  </li>

                  {/* <Link to='/admission'><button className='btn btn-warning rounded-3 mt-2' style={window.innerWidth < 575 ? { position:'absolute', bottom:'10px', width:'92%' } : null}>
                    Admission
                    </button></Link> */}

                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>


    </section>

  )
}

export default Navbar