import React from 'react'
import banner from '../Assets/banner_white.png';

const About = () => {
  return (
    <div style={{ marginTop: window.innerWidth < 575 ? '90px' : '160px' }} >  

        
         <div className={`my-5 aboutBgImg ${window.innerWidth > 575 ? 'd-flex align-items-center justify-content-center' : null}`} style={{height: window.innerWidth < 575 ? null : '100vh'}}>
          <div className='container '> 
          <div className="row px-2 py-4 m-0  ">
            <div className="col-lg-6 col-md-12 col-sm-12">
              {/* <h2 className='text-light'>LOGO</h2> */}
              <img src={banner} alt="LOGO" height="100"/>
              
            </div>

            <div className='col-lg-6 col-md-12 col-sm-12' style={{ color: '#FFFFFF', textAlign:'justify' }}>
            <h4 style={{ fontFamily: 'Mulish', textDecoration: 'underline yellow' }} className='mb-3 text-light'>About Our School</h4>
              <p style={{ fontFamily: 'Mulish' }}>
              The aim of the school is to impart excellent education, train students to have development in all quarters and to bring to forefront the inherent talents in each student.
              </p>
              <p style={{ fontFamily: 'Mulish' }}>
              Faith in god, obedience to elders and formation of clean habits are the basis of teaching in this institution.
              </p>
              <p style={{ fontFamily: 'Mulish' }}>
                The medium of instruction of English, Anyhow, the school follows the pattern of triingual eduction consisting of English, Tamil and Hindi. <br/> The school works from Monday through Saturday and closes on all Sundays and notified holidays.
              </p>
              <p style={{ fontFamily: 'Mulish' }}>
                Implicit obedience of the rules and regulations stipulated by the Management of the school is the rudimental requirement of every student. <br /> The decision of the school management is final with regard to any dispute of any nature.
              </p>
              <p style={{ fontFamily: 'Mulish' }}>
              There is transportation fecility available and those who are desirous of using the same are requested to contact school office.
              </p>
            </div>
          </div>
          </div>
        </div> 
    </div>
  )
}

export default About