import './App.css';
import Home from './Component/Home';
import Nav from './Component/Navbar';
import { Route, Routes } from 'react-router-dom';
import Footer from './Component/Footer'
import Gallery from './Component/Gallery';
import Contact from './Component/Contact';
import MissionVission from './Component/MissionVission';
import ChairmanMsg from './Component/ChairmanMsg';
import PrinicipalMsg from './Component/PrinicipalMsg';
import About from './Component/About';
import Admission from './Component/Admission';

function App() {
  return (
    <div>
      <Nav />
      <div style={{ minHeight: '80vh' }}>
        <Routes >
          <Route path='/' element={<Home />}></Route>
          <Route path='/gallery' element={<Gallery />}></Route>
          <Route path='/contact' element={<Contact />}></Route>
          <Route path='/Mission-and-Vission' element={<MissionVission />}> </Route>
          <Route path='/Chairman-Message' element={<ChairmanMsg />}> </Route>
          <Route path='/Principal-Message' element={<PrinicipalMsg />}> </Route>
          <Route path='/about-us' element={<About />}> </Route>
          <Route path='/admission' element={<Admission />}> </Route>
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
