import React, { useState } from 'react'
import banner from '../Assets/footer.png';

const Contact = () => {
    const [user, setUser] = useState({
        Name: "",
        Number: "",
        Email: "",
        subject: "",
        Message: "",
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { Name,  Number,  Email, subject,  Message }  = user;
        let options = {
            method:'POST',
            headers : {
                'Content-Type': 'aplication/json'
            },
            body:JSON.stringify({
                Name,  Number,  Email, subject,  Message
            })
        }

        const res= await fetch('https://school-site-472e4-default-rtdb.firebaseio.com/ContactFormata.json',
        options)

        if(res){
            alert("Form Submitted")
            setUser({
                Name: "",
                Number: "",
                Email: "",
                subject: "",
                Message: "",
            })
        }
        else{
            alert("ERROR")
        }
    };
    console.log(user);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser((prevUser) => ({ ...prevUser, [name]: value }));
    };

    return (
        <div style={{ marginTop: window.innerWidth < 575 ? '90px' : '180px' }} className='container'>
            <div className='text-center'>
                <img src={banner} alt="LOGO" height="120"/>
            </div>

            <div className='row mt-5'>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3>Contact Info</h3>

                    <div className="row pt-3 d-flex align-items-start">
                        <div className="col-2"><i className="fa-solid fa-location-dot conIcon"></i> </div>
                        <div className="col-10 p-0 "><p className='p-0' style={{ fontSize: '14px', fontWeight: 'bold', fontFamily: 'roboto', lineHeight: '1.6rem' }}>
                            64/B, CHENGANMAL KAYAR MAIN ROAD, THAIYUR, KELAMBAKKAM, CHENNAI - 603103, TAMILNADU, INDIA.
                        </p></div>
                    </div>

                    <div className="row  d-flex align-items-start">
                        <div className="col-2 "><i className=" fa-solid fa-mobile-screen-button conIcon"></i> </div>
                        <div className="col-10 p-0"><p className='p-0' style={{ fontSize: '14px', fontWeight: 'bold', fontFamily: 'roboto', lineHeight: '1.6rem' }}>
                            +91 98745394873<br />
                            +91 98745394873
                        </p></div>
                    </div>


                    <div className="row  d-flex align-items-start">
                        <div className="col-2"><i className="fa-solid fa-envelope conIcon"></i> </div>
                        <div className="col-10 p-0"><p className='p-0' style={{ fontSize: '14px', fontWeight: 'bold', fontFamily: 'roboto', lineHeight: '1.6rem' }}>
                            For Admission <br />
                            sample@gmil.com
                        </p></div>
                    </div>

                    <div className="row  d-flex align-items-start">
                        <div className="col-2"><i className="fa-solid fa-envelope conIcon"></i> </div>
                        <div className="col-10 p-0"><p className='p-0' style={{ fontSize: '14px', fontWeight: 'bold', fontFamily: 'roboto', lineHeight: '1.6rem' }}>
                            For Information <br />
                            sample@gmil.com
                        </p></div>
                    </div>
                </div>


                <div className="col-lg-6 col-md-6 col-sm-12 pb-3">

                    <h3>Contact Form</h3>

                    <p>Feel free to call us directly or simply complete our form and we will follow up with you.</p>

                    <form onSubmit={handleSubmit} style={{zIndex:'0' }}>
                        <div className="form-floating mb-3 mt-3"   style={{zIndex:'0' }}>
                            <input 
                          
                                value={user.Name}
                                onChange={handleChange}
                                required
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="Your Name"
                                name="Name"
                            />
                            <label htmlFor="name">Your Name</label>
                        </div>

                        <div className="form-floating mt-3 mb-3"  style={{zIndex:'0' }}>
                            <input
                                value={user.Number}
                                onChange={handleChange}
                                required
                                type="number"
                                className="form-control"
                                id="number"
                                placeholder="Your Mobile Number"
                                name="Number"
                            />
                            <label htmlFor="number">Your Mobile Number</label>
                        </div>

                        <div className="form-floating mt-3 mb-3"  style={{zIndex:'0' }}>
                            <input
                                value={user.Email}
                                onChange={handleChange}
                                required
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="Your Email"
                                name="Email"
                            />
                            <label htmlFor="email">Your Email</label>
                        </div>

                        <div className="form-floating mt-3 mb-3"  style={{zIndex:'0' }}>
                            <input
                                value={user.subject}
                                onChange={handleChange}
                                required
                                type="text"
                                className="form-control"
                                id="subject"
                                placeholder="Subject"
                                name="subject"
                            />
                            <label htmlFor="subject">Subject</label>
                        </div>

                        <div className="form-floating mt-3 mb-3"  style={{zIndex:'0' }}>
                            <textarea
                                value={user.Message}
                                onChange={handleChange}
                                required
                                className="form-control"
                                id="message"
                                placeholder="Message"
                                name="Message"
                            />
                            <label htmlFor="message">Message</label>
                        </div>

                        <button type="submit" className="btn btn-primary">
                            Submit
                        </button>
                    </form>
                </div>


            </div>


            <div className='my-5 container'>
                <h3 style={{fontWeight:'bold', fontFamily:'Mulish'}}>School Location</h3>
                <div className='d-flex align-items-center  justify-content-center'> 
            <iframe className='rounded-2 shadow-sm w-100' title='mapping' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15564.239888218293!2d79.6825008!3d12.7746173!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52dd6bae8fcfff%3A0x39ac597f4a93a7e9!2sSri%20Maruthi%20Vidyalaya%20Nursery%20and%20Primary%20school!5e0!3m2!1sen!2sin!4v1703340699563!5m2!1sen!2sin" width="600" height="450" style={{border:'0'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
            </div>
        </div>
    )
}

export default Contact