import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import {getFirestore} from "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyBWop_TyiXtfCJNG3itdMqpByWDQQ5JC0c",
  authDomain: "school-site-472e4.firebaseapp.com",
  projectId: "school-site-472e4",
  storageBucket: "school-site-472e4.appspot.com",
  messagingSenderId: "625248195056",
  appId: "1:625248195056:web:55803c8c78d01436a58d35",
  measurementId: "G-YSPN420319"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const storage = getStorage(firebaseApp);

const db = getFirestore(firebaseApp);

export { storage , db};