import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import transport from '../Assets/transport.png'
import camera from '../Assets/camera.png'
import sports from '../Assets/sports.png'
import student from '../Assets/student.png'
import assessment from '../Assets/assessment.png'
import classroom from '../Assets/classroom.png'
import lab from '../Assets/lab.png'
import { storage } from '../firebase';
import { getDownloadURL, listAll, ref } from "firebase/storage";
import Slider from 'react-slick'
import Countup from 'react-countup'
import ScrollTrigger from 'react-scroll-trigger'
import { collection, query, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Home = () => {
  const [photos, setPhotos] = useState([]);
  const [but1, setBut1] = useState(true)
  const [counterOn, setCounteron] = useState(false)

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const collectionRef = collection(db, 'News&Events');

        const dataQuery = query(collectionRef);
        const dataListener = onSnapshot(dataQuery, (snapshot) => {
          const dataArray = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setData(dataArray);
        });

        return () => {
          dataListener();
        };
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  console.log(data);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  useEffect(() => {
    const fetchImageURLs = async () => {
      try {
        const imgs = await listAll(ref(storage, "CarouselImages"));
        const imgPromises = imgs.items.map(async (val) => {
          const url = await getDownloadURL(val);
          return {
            src: url,
          };
        });

        const imgUrls = await Promise.all(imgPromises);
        setPhotos(imgUrls);
      } catch (error) {
        console.error("Error fetching image URLs:", error);
      }
    };

    fetchImageURLs();
  }, []);



  return (
    <div>
      <Carousel>
        {photos.map((photo, index) => (
          <Carousel.Item key={index} id='Carousel'>
            <div style={{ height: window.innerWidth < 550 ? '300px' : window.innerWidth < 1100 ? '500px' : '80vh' }}>
              <img
                className="d-block w-100 img-fluid"
                src={photo.src}
                alt={`Slide ${index}`}
                style={{ height: '100%', objectFit: 'cover' }}
              />
            </div>
          </Carousel.Item>
        ))}
      </Carousel>


      <div className='container'>
        <div className='my-5 '>
          <h2 className='scale-up-ver-center' style={{ fontWeight: 'bold' }}> Why to Choose Shri Maruthi Vidyalaya?</h2>

          <div className="row pt-3">
            <div className={`col-lg-4 col-md-4 col-sm-12 mt-3 ${window.innerWidth < 767 ? 'mt-4' : null}`}>
              <div className='border rounded-2 shadow  p-3 d-flex align-items-center justify-content-center flex-column'>
                <img src={assessment} alt="assessment" width='60px' />
                <div className='pt-3'>
                  <h5 style={{ color: '#002147' }}>Assessments</h5>
                  <p style={{ fontSize: '13px', lineHeight: '1.3rem' }} className='p-0 m-0 '>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam laboriosam ab, amet soluta sint dolore ad, at vel</p>
                </div>
              </div>
            </div>

            <div className={`col-lg-4 col-md-4 col-sm-12 mt-3 ${window.innerWidth < 767 ? 'mt-4' : null}`} >
              <div className='border rounded-2 shadow  p-3 d-flex align-items-center justify-content-center flex-column'>
                <img src={lab} alt="lab" width='60px' />
                <div className='pt-3'>
                  <h5 style={{ color: '#002147' }}>Interactive Laboratories</h5>
                  <p style={{ fontSize: '13px', lineHeight: '1.3rem' }} className='p-0 m-0 '>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam laboriosam ab, amet soluta sint dolore ad, at vel</p>
                </div>
              </div>
            </div>

            <div className={`col-lg-4 col-md-4 col-sm-12 mt-3 ${window.innerWidth < 767 ? 'mt-4' : null}`}  >
              <div className='border rounded-2 shadow p-3 d-flex align-items-center justify-content-center flex-column'>
                <img src={classroom} alt="classroom" width='60px' />
                <div className='pt-3'>
                  <h5 style={{ color: '#002147' }}>Digital Classrooms</h5>
                  <p style={{ fontSize: '13px', lineHeight: '1.3rem' }} className='p-0 m-0 '>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam laboriosam ab, amet soluta sint dolore ad, at vel</p>
                </div>
              </div>
            </div>

          </div>


          <div className="row pt-3">
            <div className={`col-lg-4 col-md-4 col-sm-12 mt-3 ${window.innerWidth < 767 ? 'mt-4' : null}`}>
              <div className='border rounded-2 shadow  p-3 d-flex align-items-center justify-content-center flex-column'>
                <img src={transport} alt="transport" width='60px' />
                <div className='pt-3'>
                  <h5 style={{ color: '#002147' }}>Transport Network</h5>
                  <p style={{ fontSize: '13px', lineHeight: '1.3rem' }} className='p-0 m-0 '>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam laboriosam ab, amet soluta sint dolore ad, at vel</p>
                </div>
              </div>
            </div>

            <div className={`col-lg-4 col-md-4 col-sm-12 mt-3 ${window.innerWidth < 767 ? 'mt-4' : null}`} >
              <div className='border rounded-2 shadow  p-3 d-flex align-items-center justify-content-center flex-column'>
                <img src={camera} alt="transport" width='60px' />
                <div className='pt-3'>
                  <h5 style={{ color: '#002147' }}>Security</h5>
                  <p style={{ fontSize: '13px', lineHeight: '1.3rem' }} className='p-0 m-0 '>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam laboriosam ab, amet soluta sint dolore ad, at vel</p>
                </div>
              </div>
            </div>

            <div className={`col-lg-4 col-md-4 col-sm-12 mt-3 ${window.innerWidth < 767 ? 'mt-4' : null}`}  >
              <div className='border rounded-2 shadow p-3 d-flex align-items-center justify-content-center flex-column'>
                <img src={sports} alt="transport" width='60px' />
                <div className='pt-3'>
                  <h5 style={{ color: '#002147' }}>Sports</h5>
                  <p style={{ fontSize: '13px', lineHeight: '1.3rem' }} className='p-0 m-0 '>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam laboriosam ab, amet soluta sint dolore ad, at vel</p>
                </div>
              </div>
            </div>

          </div>
        </div>



        <div className='row w-100 mx-0 my-5 '>
          <img className="col-lg-6 col-sm-12 rounded-3 " src="https://media.istockphoto.com/id/648418766/photo/school-children-writing-on-slate.jpg?s=612x612&w=0&k=20&c=nInoLR8mWZ3XjhiOKijJvN5CrC9-nzhQDw3P4Ivp5bM=" alt="" />
          <div className={`col-lg-6 col-sm-12 ${window.innerWidth < 992 ? 'pt-4' : null}`} >
            <h2 style={{ fontWeight: 'bold', margin: window.innerWidth < 575 ? '10px 0' : null }}>Welcome to <br /> Sri Maruthi Vidyalaya Nursery & Primary School</h2>
            <p>
              The aim of the school is to impart excellent education, train students to have development in all quarters and to bring to forefront the inherent talents in each student.
            </p>
            <p>
              Faith in god, obedience to elders and formation of clean habits are the basis of teaching in this institution.
            </p>
            <button className='btn btn-primary rounded-4'>Read more</button>
          </div>
        </div>
      </div>



      <div className='p-2 homeBG' style={{ height: 'auto' }}>
        <h2 className="text-center mt-4" > <span className='py-2 px-3 rounded-2 ' style={{ color: 'white', fontFamily: 'Mulish', fontWeight: 'bolder', fontSize: window.innerWidth <575 ? '25' :'45px' }}>Learning For Career Excellence</span> </h2>

        <ScrollTrigger onEnter={() => setCounteron(true)} onExit={() => setCounteron(false)}>
          <div className='container'>
            <div className="row mt-5" >
              <div className="col-lg-4 col-md-4 col-sm-12 mb-5 d-flex align-items-center flex-column">
                <img src={student} alt="" width='90px' style={{ color: 'black' }} />

                {counterOn && <h1 className='text-light text-center'> <Countup start={0} end={283} duration={2} delay={0} />+ </h1>}

                <h4 className='text-light text-center'>Students</h4>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-12 mb-5 d-flex align-items-center flex-column">
                <i class="fa-solid fa-people-group" style={{ fontSize: '110px', color: 'white' }}></i>
                {counterOn && <h1 className='text-light text-center'> <Countup start={0} end={15} duration={2} delay={0} />+ </h1>}
                <h4 className='text-light text-center'>Teachers</h4>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-12 mb-3 d-flex align-items-center flex-column">
                <i class="fa-solid fa-trophy" style={{ fontSize: '100px', color: 'white' }}></i>
                {counterOn && <h1 className='text-light text-center'> <Countup start={0} end={10} duration={2} delay={0} />+ </h1>}
                <h4 className='text-light text-center'>Achievements</h4>

              </div>
            </div>
          </div>
        </ScrollTrigger>
      </div>


      <div className='container'>
        <div className="row mx-0 my-5  ">
          <div className='mt-3 mb-5 d-flex align-items-center justify-content-center'>
            <button id='btn1' style={((but1) ? { background: 'rgb(255, 193, 7)' } : { background: 'none' })} onClick={() => setBut1(true)}>News</button>
            <button id='btn2' style={(!but1) ? { background: 'rgb(255, 193, 7)' } : { background: 'none' }} onClick={() => setBut1(false)}>Events</button>
          </div>
          {!but1 && <>
            <div className='my-3'>
            <Slider {...settings}>
                {data
                  .filter(d => d.EventData)
                  .map(d => {
                    const eventDate = d.EventData.date ? d.EventData.date.toDate() : null;
                    const formattedDate = eventDate ? eventDate.toLocaleDateString() : 'No Date';

                    return (
                      <div key={d.id} className={`bg-light text-dark my-2 rounded-4 shadow`}>
                        <div style={{ height: '250px' }} className='rounded-3 bg-primary d-flex align-items-center justify-content-center'>
                          <img src={d.EventData.img} alt="" style={{ objectFit: 'cover', height: '100%', width: '100%' }} className='shadow-sm rounded-3 img-fluid' />
                        </div>

                        <div className='d-flex flex-column align-items-center justify-content-center p-2' style={{ gap: '3px' }}>
                          <h5>{d.EventData.title}</h5>
                          <p className='text-secondary'>{formattedDate}</p>
                        </div>
                      </div>
                    );
                  })}
              </Slider>

            </div>
          </>}

          {but1 && <>
            <div className='my-3'>
              <Slider {...settings}>
                {data
                  .filter(d => d.NewsData)
                  .map(d => {
                    const eventDate = d.NewsData.date ? d.NewsData.date.toDate() : null;
                    const formattedDate = eventDate ? eventDate.toLocaleDateString() : 'No Date';

                    return (
                      <div key={d.id} className={`bg-light text-dark my-2 rounded-4 shadow`}>
                        <div style={{ height: '250px' }} className='rounded-3 bg-primary d-flex align-items-center justify-content-center'>
                          <img src={d.NewsData.img} alt="" style={{ objectFit: 'cover', height: '100%', width: '100%' }} className='shadow-sm rounded-3 img-fluid' />
                        </div>

                        <div className='d-flex flex-column align-items-center justify-content-center p-2' style={{ gap: '3px' }}>
                          <h5>{d.NewsData.title}</h5>
                          <p className='text-secondary'>{formattedDate}</p>
                        </div>
                      </div>
                    );
                  })}
              </Slider>


            </div>
          </>}
        </div>
      </div>

    </div>
  )
}

export default Home