import React, { useState } from 'react'

const Admission = () => {
    const [user, setUser] = useState({
        studentName: "",
        studentGender: "",
        studentDOB: "",
        studentAge: "",
        studentClass: "",
        studentPrevClass: "",
        address: "",
        fatherName: "",
        fatherQualification: "",
        fatherOcc: "",
        fatherNumber: "",
        motherName: "",
        motherQualification: "",
        motherOcc: "",
        motherNumber: ""
    });

    const handleSubmit = async (e) => {
        e.preventDefault()
        const { studentName ,
        studentGender,
        studentDOB,
        studentAge,
        studentClass,
        studentPrevClass,
        address,
        fatherName,
        fatherQualification,
        fatherOcc,
        fatherNumber,
        motherName,
        motherQualification,
        motherOcc,
        motherNumber}  = user;
        let options = {
            method:'POST',
            headers : {
                'Content-Type': 'aplication/json'
            },
            body:JSON.stringify({
                studentName ,
                studentGender,
                studentDOB,
                studentAge,
                studentClass,
                studentPrevClass,
                address,
                fatherName,
                fatherQualification,
                fatherOcc,
                fatherNumber,
                motherName,
                motherQualification,
                motherOcc,
                motherNumber
            })
        }

        const res= await fetch('https://school-site-472e4-default-rtdb.firebaseio.com/AdmissionFormData.json',
        options)

        if(res){
            alert("Form Submitted")
        }
        else{
            alert("ERROR")
        }

        setUser({
            studentName: "",
            studentGender: "",
            studentDOB: "",
            studentAge: "",
            studentClass: "",
            studentPrevClass: "",
            address: "",
            fatherName: "",
            fatherQualification: "",
            fatherOcc: "",
            fatherNumber: "",
            motherName: "",
            motherQualification: "",
            motherOcc: "",
            motherNumber: ""
        });
    }

    const handleGenderChange = (selectedGender) => {
        setUser((prevUser) => ({
            ...prevUser,
            studentGender: selectedGender
        }));
    };


    console.log(user);


    return (
        <div className='container' style={{ marginTop: window.innerWidth < 575 ? '90px' : '120px' }}>

            <h2>Admission Form</h2>
            <form className='mb-5' onSubmit={handleSubmit} >

                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <div className="form-floating mb-3 mt-3" >
                            <input
                                value={user.studentName}
                                onChange={(e) => setUser((prevUser) => ({ ...prevUser, studentName: e.target.value }))}
                                required
                                type="text"
                                className="form-control"
                                placeholder="Your Name"
                                name="Name"/>
                            <label htmlFor="name">Your Name</label>
                        </div>
                    </div>

                    <div className="col-lg-6 col-sm-12">
                        <br />
                        <label htmlFor="gender">Gender:</label> <br />
                        <input
                            type="radio"
                            id="male"
                            value="male"
                            name="gender"
                            onChange={() => handleGenderChange('male')}
                            checked={user.studentGender === 'male'}
                        /> <span>Male</span>&nbsp;&nbsp;&nbsp;&nbsp;
                        <input
                            type="radio"
                            id="female"
                            value="female"
                            name="gender"
                            onChange={() => handleGenderChange('female')}
                            checked={user.studentGender === 'female'}
                        /> <span>Female</span>
                    </div>

                </div>

                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <div className="form-floating mb-3 mt-3" style={{ zIndex: '0' }}>
                            <input
                                value={user.studentDOB}
                                onChange={(e) => setUser((prevUser) => ({ ...prevUser, studentDOB: e.target.value }))}
                                required
                                type="date"
                                className="form-control"
                                id="DOB"
                                placeholder="DOB"
                                name="DOB"
                            />
                            <label htmlFor="DOB">Date of Birth</label>
                        </div>
                    </div>

                    <div className="col-lg-6 col-sm-12">

                        <div className="form-floating mt-3 mb-3" style={{ zIndex: '0' }}>
                            <input
                                value={user.studentAge}
                                onChange={(e) => setUser((prevUser) => ({ ...prevUser, studentAge: e.target.value }))}
                                required
                                type="number"
                                className="form-control"
                                id="Age"
                                placeholder="Age"
                                name="Age"
                            />
                            <label htmlFor="Age">Age</label>
                        </div></div>
                </div>



                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <div className="mb-3 mt-3" style={{ zIndex: '0' }}>
                            <div className="form-floating">
                                <select name="" id="" className='form-control'
                                    value={user.studentClass}
                                    onChange={(e) => setUser((prevUser) => ({ ...prevUser, studentClass: e.target.value }))}
                                    >
                                    <option value="Pre-Nursery">Pre-Nursery</option>
                                    <option value="Nursery">Nursery</option>
                                    <option value="KG">KG</option>
                                    <option value="1st">1st</option>
                                    <option value="2nd">2nd</option>
                                    <option value="3rd">3rd</option>
                                </select>
                                <label htmlFor="name" style={{ zIndex: '0' }}>Select Class and year for which admission is required</label>
                            </div>
                        </div>

                    </div>

                    <div className="col-lg-6 col-sm-12">

                        <div className="form-floating mt-3 mb-3" style={{ zIndex: '0' }}>
                            <input
                                value={user.studentPrevClass}
                                onChange={(e) => setUser((prevUser) => ({ ...prevUser, studentPrevClass: e.target.value }))}
                                type="text"
                                className="form-control"
                                placeholder="Your Mobile Number"
                                name="Number"
                            />
                            <label htmlFor="number">Last School Attended (if any)</label>
                        </div></div>
                </div>


                <div className="row">


                    <div className="col-lg-12 col-sm-12">

                        <div className="form-floating mt-3 mb-3" style={{ zIndex: '0' }}>
                            <textarea
                                value={user.address}
                                onChange={(e) => setUser((prevUser) => ({ ...prevUser, address: e.target.value }))}
                                required
                                className="form-control"
                                id="address"
                                name="address"
                            > </textarea>
                            <label htmlFor="address">Residential Address</label>
                        </div></div>
                </div>

                <h4 className='mt-3'>Family Details</h4>
                <p className='mb-0 mt-4'>Details for Father</p>

                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <div className="form-floating mb-3 mt-3" style={{ zIndex: '0' }}>
                            <input
                                value={user.fatherName}
                                onChange={(e) => setUser((prevUser) => ({ ...prevUser, fatherName: e.target.value }))}
                                required
                                type="text"
                                className="form-control"
                                placeholder="Your Name"
                                name="Name"
                            />
                            <label htmlFor="name">Name</label>
                        </div>
                    </div>

                    <div className="col-lg-6 col-sm-12">
                        <div className="form-floating mb-3 mt-3" style={{ zIndex: '0' }}>
                            <input
                                value={user.fatherQualification}
                                onChange={(e) => setUser((prevUser) => ({ ...prevUser, fatherQualification: e.target.value }))}
                                required
                                type="text"
                                className="form-control"
                                placeholder="Qualification"
                                name="Qualification"
                            />
                            <label htmlFor="Qualification">Qualification</label>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <div className="form-floating mb-3 mt-3" style={{ zIndex: '0' }}>
                            <input
                                value={user.fatherOcc}
                                onChange={(e) => setUser((prevUser) => ({ ...prevUser, fatherOcc: e.target.value }))}
                                required
                                type="text"
                                className="form-control"
                                placeholder="Occupation"
                                name="Occupation"
                            />
                            <label htmlFor="Occupation">Occupation</label>
                        </div>
                    </div>

                    <div className="col-lg-6 col-sm-12">
                        <div className="form-floating mb-3 mt-3" style={{ zIndex: '0' }}>
                            <input
                                value={user.fatherNumber}
                                onChange={(e) => setUser((prevUser) => ({ ...prevUser, fatherNumber: e.target.value }))}
                                required
                                type="text"
                                className="form-control"
                                placeholder="Mobile Number"
                                name="Mobile Number"
                            />
                            <label htmlFor="Mobile Number">Mobile Number</label>
                        </div>
                    </div>
                </div>

                <p className='mb-0 mt-4'>Details for Mother</p>

                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <div className="form-floating mb-3 mt-3" style={{ zIndex: '0' }}>
                            <input
                                value={user.motherName}
                                onChange={(e) => setUser((prevUser) => ({ ...prevUser, motherName: e.target.value }))}
                                required
                                type="text"
                                className="form-control"
                                placeholder="Name"
                                name="Name"
                            />
                            <label htmlFor="name">Name</label>
                        </div>
                    </div>

                    <div className="col-lg-6 col-sm-12">
                        <div className="form-floating mb-3 mt-3" style={{ zIndex: '0' }}>
                            <input
                                value={user.motherQualification}
                                onChange={(e) => setUser((prevUser) => ({ ...prevUser, motherQualification: e.target.value }))}
                                required
                                type="text"
                                className="form-control"
                                placeholder="Qualification"
                                name="Qualification"
                            />
                            <label htmlFor="Qualification">Qualification</label>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <div className="form-floating mb-3 mt-3" style={{ zIndex: '0' }}>
                            <input
                                value={user.motherOcc}
                                onChange={(e) => setUser((prevUser) => ({ ...prevUser, motherOcc: e.target.value }))}
                                required
                                type="text"
                                className="form-control"
                                placeholder="Your Occupation"
                                name="Occupation"
                            />
                            <label htmlFor="Occupation">Occupation</label>
                        </div>
                    </div>

                    <div className="col-lg-6 col-sm-12">
                        <div className="form-floating mb-3 mt-3" style={{ zIndex: '0' }}>
                            <input
                                value={user.motherNumber}
                                onChange={(e) => setUser((prevUser) => ({ ...prevUser, motherNumber: e.target.value }))}
                                required
                                type="text"
                                className="form-control"
                                placeholder="Mobile Number"
                                name="Mobile Number"
                            />
                            <label htmlFor="Mobile Number">Mobile Number</label>
                        </div>
                    </div>
                </div>

                <div className="mt-3"> <input type="checkbox" required />
                    <span >I certify that the above particulars given by me are true and I agree to abide by the rules of the School.</span>
                </div>

                <button type="submit" className="mt-3 btn btn-primary">
                    Submit
                </button>
            </form>
        </div>
    )
}

export default Admission