import React from 'react'
import banner from '../Assets/banner_white.png';


const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <div style={{ background: '#1F365C', color: 'white' }}>
            <div className="container">
                <div className='row px-3 pt-4 m-0 ' >
                    <div className='col-lg-6  col-md-4 col-sm-12'>
                        {/* <h1>LOGO</h1> */}
                        <img src={banner} alt="LOGO" height="100"/>
                        {/* <p className='pt-3' style={{ fontSize: '14px', fontFamily: 'Mulish', textAlign: 'left', lineHeight: '1.3rem', }}>
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Placeat ut sequi quidem ad animi voluptas obcaecati non nobis asperiores consequuntur? Quidem officiis est ad minus sed vitae sint obcaecati sunt.
                        </p> */}
                    </div>


                    <div className='col-lg-4 col-md-4 col-sm-12'>
                        <h6 className='footUnder' style={{fontWeight:'bold'}}>CONTACT INFO</h6>

                        <div className="row pt-3 d-flex align-items-start">
                            <div className="col-2"><i className="fa-solid fa-location-dot"></i> </div>
                            <div className="col-10 p-0 "><p className='p-0' style={{ fontSize: '12px', fontFamily: 'Mulish', lineHeight: '1.3rem' }}>
                                DUSI VILLAGE AND POST, VEMPAKKAM TALUK, CHEYYAR, TAMILNADU, INDIA.
                            </p></div>
                        </div>
                        <div className="row">
                            <div className="col-2 "><i className="fa-solid fa-mobile-screen-button"></i></div>
                            <div className="col-10 p-0 "><p className='p-0' style={{ fontSize: '12px', fontFamily: 'Mulish', lineHeight: '1.3rem' }}>
                                044 27242484, 99941 71537
                            </p></div>
                        </div>

                        <div className="row">
                            <div className="col-2"><i className="fa-solid fa-envelope"></i></div>
                            <div className="col-10 p-0 "><p className='p-0' style={{ fontSize: '12px', fontFamily: 'Mulish', lineHeight: '1.3rem' }}>
                            shreemaruthividyalaya@gmail.com
                            </p></div>
                        </div>

                    </div>

                    <div className='col-lg-2 col-md-3 col-sm-12'>
                    <section className="mb-4">
                        <a className="btn btn-outline-light btn-floating m-1" href="https://www.facebook.com/ShreeMaruthiVidyalaya" role="button" target='_blank'
                        ><i className="fab fa-facebook-f"></i></a>

                        {/* <a className="btn btn-outline-light btn-floating m-1" href="#!" role="button"
                        ><i className="fab fa-twitter"></i></a> */}

                        <a className="btn btn-outline-light btn-floating m-1" href="https://www.instagram.com/srimaruthividyalaya/" role="button" target='_blank'
                        ><i className="fab fa-instagram"></i></a>

                        {/* <a className="btn btn-outline-light btn-floating m-1" href="#!" role="button"
                        ><i className="fab fa-linkedin-in"></i></a> */}

                       </section>
                    </div>

                 

                </div>
            </div>

            <footer className="bg-dark text-center text-white" >
        
                <div className="text-center text-secondary p-3" style={{ backgroundColor: ' #002147', fontSize:'small' }}>
                    ©  {currentYear} Copyright:
                    <a className="footerMenu" href="/" style={{textDecoration:'none'}}>&nbsp; Shri Maruthi Vidyalaya</a>
                </div>
            </footer>
        </div>
    )
}

export default Footer