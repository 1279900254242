import React from 'react'

const ChairmanMsg = () => {
  return (
    <div style={{ marginTop: window.innerWidth < 575 ? '90px' : '160px' }} >

      <div className='bgImage d-flex align-items-center mb-3' >
         <h2 className='text-light px-4'>Chairman's Message</h2>
      </div>
      <div className='container  px-3 my-4'>
        <h2 style={{textDecoration:'#FFCA2C underline', fontFamily: 'Mulish', width: window.innerWidth < 992 ? '100%' : '75%',  marginLeft: window.innerWidth < 992 ? '0%' : '25%' }} className='py-3 text-center'>Inspire and Empower</h2>

        <div className="row">
          <div className="col-lg-3 col-sm-12 d-flex align-items-center justify-content-center">
            <img className='border img-fluid' src="https://tsis.edu.in/wp-content/uploads/2021/03/SR-768x950.jpg" alt="principalIMG" />
          </div>

          <div className='col-lg-9 col-sm-12' style={{ textAlign: 'justify', marginTop:window.innerWidth < 575 ? '20px' : '0' }}>
            <p style={{ fontFamily: 'Mulish', fontSize: '16px' }} >Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae perferendis facere quasi magnam porro alias,
              labore aut veniam molestiae a vitae placeat unde impedit nulla doloribus, dolores illum aliquid? Laborum?
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt voluptatibus rerum magni quaerat odio perferendis nobis similique facere veritatis est alias dolore ipsum sit exercitationem,
              officia ea optio beatae expedita?</p>
            <p style={{ fontFamily: 'Mulish', fontSize: '16px' }} >   Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae perferendis facere quasi magnam porro alias,
              labore aut veniam molestiae a vitae placeat unde impedit nulla doloribus, dolores illum aliquid? Laborum?
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt voluptatibus rerum magni quaerat odio perferendis nobis similique facere veritatis est alias dolore ipsum sit exercitationem,
              officia ea optio beatae expedita?</p>
          </div>
        </div>
      </div>

    </div>
  )
}

export default ChairmanMsg