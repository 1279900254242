import React, { useEffect, useState } from "react";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { storage } from '../firebase';
import { getDownloadURL, listAll, ref } from "firebase/storage";

const App = () => {
  const [index, setIndex] = useState(-1);
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchImageURLs = async () => {
      try {
        const imgs = await listAll(ref(storage, "GalleryImages"));
        const imgPromises = imgs.items.map((val) => getDownloadURL(val));
        const imgUrls = await Promise.all(imgPromises);

        setPhotos(imgUrls.map((url, index) => ({
          src: url,
          width: 1080,
          height: 900,
          id: index,
        })));

        setLoading(false); 
      } catch (error) {
        console.error("Error fetching image URLs:", error);
        setLoading(false); 
      }
    };

    fetchImageURLs();
  }, []);

  return (
    <div style={{ marginTop: window.innerWidth < 575 ? '90px' : '180px' }} className="container">
      <h3 className="text-center" style={{ fontFamily: 'Mulish', textDecoration:'#FFCA2C  underline' }}>GALLERY</h3>
      <div className="lg-react-element mb-5">
        {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
            <div className="spinner-border text-primary"></div>
          </div>) : (
          <PhotoAlbum
            photos={photos}
            layout="rows"
            targetRowHeight={150}
            onClick={({ index }) => setIndex(index)}
          />
        )}
      </div>

      <Lightbox
        slides={photos}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
        overlayStyles={{
          background: 'rgba(10, 10, 10, 1.3)',
        }}
      />
    </div>
  );
};

export default App;
