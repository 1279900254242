import React from 'react'
import mission from '../Assets/mission.png'
import vission from '../Assets/vission.png'
const MissionVission = () => {
    return (
        <div style={{ marginTop: '180px' }} className='container mb-5' >
            <h2 className='mb-3' style={{ fontFamily: 'Mulish' }}>MISSION AND VISSION</h2>
            <div className="row m-0 mb-4">
                <div className="col-lg-6 col-sm-12 d-flex align-items-center justify-content-center ">
                    <img src={vission} width='200px' alt="Mission and Vission" />
                </div>

                <div className="col-lg-6 col-sm-12" style={{ fontFamily: 'Mulish', textAlign:'justify' }}>
                    <h4 className='pt-3'> Vission</h4>
                    <p style={{ fontFamily: 'Mulish' }}>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores corporis similique ut deleniti perspiciatis officia autem reiciendis voluptatum. Voluptatibus nulla distinctio dicta, atque officia autem asperiores nostrum vero reiciendis ducimus.
                    </p>
                    <p style={{ fontFamily: 'Mulish' }}>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores corporis similique ut deleniti perspiciatis officia autem reiciendis voluptatum. Voluptatibus nulla distinctio dicta, atque officia autem asperiores nostrum vero reiciendis ducimus.
                    </p>
                </div>
            </div>

            <div className="row m-0">
             <div className="col-lg-6 col-sm-12" style={{ fontFamily: 'Mulish', textAlign:'justify' }}>
                    <h4>Mission</h4>
                    <p style={{ fontFamily: 'Mulish' }}>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores corporis similique ut deleniti perspiciatis officia autem reiciendis voluptatum. Voluptatibus nulla distinctio dicta, atque officia autem asperiores nostrum vero reiciendis ducimus.
                    </p>
                    <p style={{ fontFamily: 'Mulish' }}>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores corporis similique ut deleniti perspiciatis officia autem reiciendis voluptatum. Voluptatibus nulla distinctio dicta, atque officia autem asperiores nostrum vero reiciendis ducimus.
                    </p>

                </div>
                <div className="col-lg-6 col-sm-12 d-flex align-items-center justify-content-center ">
                    <img src={mission} width='200px' alt="Mission and Vission" />
                </div>

            </div>
        </div>
    )
}

export default MissionVission